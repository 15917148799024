import { ChangeDetectorRef, Directive, Inject, OnInit, Optional, Self, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[cwtForwardCVA]',
  standalone: true
})
export class ForwardCvaDirective implements OnInit, OnDestroy {
  private control: AbstractControl;
  private _old: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(NgControl) @Optional() private ngControl?: NgControl,
    @Inject(NG_VALUE_ACCESSOR) @Optional() @Self() private valueAccessors?: ControlValueAccessor[]
  ) { }

  ngOnInit(): void {
    if (!this.ngControl || !this.valueAccessors || this.valueAccessors.length !== 1) return;
    const controls: AbstractControl[] = Object.values(this.valueAccessors[0]).filter((v: unknown) => v instanceof AbstractControl);
    if (controls.length === 0) return;
    this.control = controls[0];
    this._old = this.ngControl.control.markAllAsTouched;
    this.ngControl.control.markAllAsTouched = (): void => {
      this._old.bind(this.ngControl.control)();
      this.control.markAllAsTouched();
      this.changeDetectorRef.markForCheck();
    };
  }

  ngOnDestroy(): void {
    if (!this._old || !this.ngControl?.control?.markAllAsTouched) return;
    this.ngControl.control.markAllAsTouched = this._old;
    this._old = null;
  }
}
