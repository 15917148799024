import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Viewer } from "./viewer.model";

export type QuestionStatus = 'pending' | 'trashed' | 'picked';
export enum QuestionStatusEnum {
    PENDING = 'pending',
    TRASHED = 'trashed',
    PICKED = 'picked',
}

const QuestionSearchMapper = (s) => ({
   content: { regexp: s }
})

@CwtModel({ name: 'question' })
@CwtCrudContract({ path: 'question', searchMapper: QuestionSearchMapper })
export class Question extends CwtApiEntity {
    @CwtProp() content: string;
    @CwtProp() status: QuestionStatus;
    @CwtProp({ ref: Viewer }) viewer: Viewer;
}