import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Session } from "./session.model";

const ViewerSearchMapper = (s) => ({
    or: [
        { firstName: { regexp: s } },
        { lastName: { regexp: s } },
        { email: { regexp: s } },
    ]
})

@CwtModel({ name: 'viewer' })
@CwtCrudContract({ path: '/viewer', searchMapper: ViewerSearchMapper })
export class Viewer extends CwtApiEntity {
    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() email: string;
    @CwtProp() token: string;
    @CwtProp({ ref: Session }) session: Session;
    @CwtProp() acceptCommercial:boolean
}

