import { Directive, OnInit } from '@angular/core';
import { NzSelectComponent } from 'ng-zorro-antd/select';

@Directive({ selector: 'nz-select', standalone: true })
export class FixSelectItemHeightDirective implements OnInit {
    constructor(private nzSelect: NzSelectComponent) { }

    ngOnInit(): void {
        this.nzSelect.nzOptionHeightPx = 28;
    }
}