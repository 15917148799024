import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-media-icon-addon',
  template: `
    <span nz-icon [nzType]="icon"></span>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      user-select: none;
      transition: inherit;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaIconAddonComponent implements OnInit {
  @Input() icon: string = 'picture';

  constructor() { }

  ngOnInit(): void {
  }

}
