import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { CWT_AUTH_CONFIG, CwtAuthStore } from "@cawita/core-front/auth";

export function LoggedInGuard(): CanActivateFn {
    return async (route, state) => {
        console.log('GUARD: LoggedInGuard');
        const config = inject(CWT_AUTH_CONFIG);
        const router = inject(Router);
        const auth = inject(CwtAuthStore);
        if (auth.isLoggedIn) return true;
        return router.createUrlTree(config.navigation.loginRoute, {
            queryParams: { redirect: state.url },
            queryParamsHandling: 'merge',
        });
    }
}

export function LoggedOutGuard(): CanActivateFn {
    return async (route, state) => {
        console.log('GUARD: LoggedOutGuard');
        const config = inject(CWT_AUTH_CONFIG);
        const router = inject(Router);
        const auth = inject(CwtAuthStore);
        if (!auth.isLoggedIn) return true;
        return router.createUrlTree(config.navigation.defaultRoute);
    }
}