import { Component, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MailRecipient } from '../mailing-types';
import { MailRecipientsService } from '../services/mail-recipients.service';

export type MailRecipientLike = MailRecipient<any> | string;

@Component({
  selector: 'cwt-mail-recipients-input',
  templateUrl: './mail-recipients-input.component.html',
  styleUrls: ['./mail-recipients-input.component.less'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MailRecipientsInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MailRecipientsInputComponent), multi: true }
  ]
})
export class MailRecipientsInputComponent implements OnChanges, ControlValueAccessor, Validator {

  public recipients: MailRecipient<any>[];

  public value: MailRecipientLike[] = [];
  public disabled = false;
  private _onChange = (emails: string[]) => { };
  private _onTouched = () => { };
  private _onValidatorChange = () => { };

  constructor(
    private recipientService: MailRecipientsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  writeValue(obj: string[]): void {
    this.value = Array.isArray(obj) ? obj : [];
  }

  onMailChanged(value: MailRecipientLike[]) {
    const emails = value.map(item => {
      if (typeof item === 'string') return item;
      return item.email;
    })

    this._onChange(emails);
  }

  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled; }
  registerOnChange(fn: any): void { this._onChange = fn; }
  registerOnTouched(fn: any): void { this._onTouched = fn; }
  registerOnValidatorChange(fn: () => void): void { this._onValidatorChange = fn; }

  validate(control: AbstractControl<any, any>): ValidationErrors { return null; }
}
