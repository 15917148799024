import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isset, sameId } from '@cawita/core-front';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs';
import { Template, TemplateUseCase } from '../../../models';
import { MailAction, MailParams } from '../mailing-types';
import { MailingService } from '../services';
import { ConstraintResolveOptions, MailingConstraintService } from '../services/mailing-constraint.service';

@Component({
  templateUrl: './mailing-modal.component.html',
  styleUrls: ['./mailing-modal.component.less']
})
export class MailingModalComponent implements OnInit {
  public readonly action!: MailAction;
  public readonly onSent: (event: any) => void;
  public readonly resolveConstraint = (useCase: TemplateUseCase, options: ConstraintResolveOptions) => this.constraintSvc.resolveConstraints(this.action, useCase, this._params, options);

  private _params: MailParams;

  public loading = false;
  public useCases: TemplateUseCase | TemplateUseCase[];
  public form = new FormGroup({
    emails: new FormControl<string[]>([]),
    template: new FormControl<Template | null>(null),
    content: new FormControl<string | null>(null),
    subject: new FormControl<string | null>(null),
  });

  private _templateChanged$ = this.form.get('template').valueChanges.pipe(distinctUntilChanged((a, b) => sameId(a, b)));

  constructor(
    private svc: MailingService,
    private constraintSvc: MailingConstraintService,
    private modal: NzModalRef,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._disableSubjectContent();
    this._watchTemplate();
    this.useCases = this.svc.getTemplatesUseCases(this.action);
    this._params = {

    };
  }

  public send($event) {
    this.loading = true;
    this.form.disable({ emitEvent: false });
    this.svc.send(this.action, { ...$event, params: this._params }).pipe(
      finalize(() => {
        this.loading = false;
        this.form.enable({ emitEvent: false });
        this.cdRef.markForCheck();
      })
    ).subscribe(
      (response) => {
        this.onSent?.(response);
        this.modal.close(response);
      }
    );
  }

  private _watchTemplate() {
    this._templateChanged$.pipe(
      tap(() => this._onTemplateCleared()),
      filter(isset),
      switchMap(template => this.svc.preview(this.action, { template: template, params: this._params }))
    ).subscribe(({ parsedSubject, parsedContent }) => this._onTemplateSelected(parsedSubject, parsedContent));
  }

  private _enableSubjectContent() {
    this.form.get('content').enable({ emitEvent: false });
    this.form.get('subject').enable({ emitEvent: false });
  }

  private _disableSubjectContent() {
    this.form.get('content').disable({ emitEvent: false });
    this.form.get('subject').disable({ emitEvent: false });
  }

  private _onTemplateCleared() {
    this._disableSubjectContent();
    this.form.patchValue({ content: null, subject: null }, { emitEvent: false });
    this.cdRef.markForCheck();
  }

  private _onTemplateSelected(parsedSubject: string, parsedContent: string) {
    this._enableSubjectContent();
    this.form.patchValue({ content: parsedContent, subject: parsedSubject }, { emitEvent: false });
    this.cdRef.markForCheck();
  }

}
