import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { asArray, hasSomeChanges, isset } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { Observable, of } from 'rxjs';
import { Template, TemplateUseCase } from '../../../models';
import { ConstraintResolveOptions } from '../services/mailing-constraint.service';

@Component({
  selector: 'cwt-mail-template-input',
  templateUrl: './mail-template-input.component.html',
  styleUrls: ['./mail-template-input.component.less'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MailTemplateInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MailTemplateInputComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailTemplateInputComponent implements OnChanges, ControlValueAccessor, Validator {

  @Input() useCases: TemplateUseCase | TemplateUseCase[];
  @Input() resolveConstraint: (useCase: TemplateUseCase, options: ConstraintResolveOptions) => Promise<void>;
  @ViewChild(NzSelectComponent) private select: NzSelectComponent;

  public templates$: Observable<Template[]>;
  public value: Template = null;
  public disabled = false;
  private _onChange = (template: Template) => { };
  private _onTouched = () => { };
  private _onValidatorChange = () => { };

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(CwtCrudContract.get(Template)) private templateCrud: CwtCrudService<Template>,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['useCases'], true)) {
      if (!this.useCases?.length) this.templates$ = of([]);
      else this.templates$ = this.templateCrud.getArray({ useCase: { in: asArray(this.useCases ?? []) } });
    }
  }

  writeValue(obj: string[]): void {
    this.value = obj instanceof Template ? obj : null;
  }

  async onTemplateChange(value: Template) {
    if (!isset(value)) {
      this._setSelected(null);
      this._onChange(null);
      return;
    }

    const currentValue = this.value;
    await this.resolveConstraint(value.useCase, {
      constraintsFound: () => {
        this._setSelected(null);
        this._onChange(null);
      },
      constraintsUnresolved: () => {
        this._setSelected(currentValue);
        this._onChange(currentValue);
      },
      constraintsResolved: () => {
        this._setSelected(value)
        this._onChange(value);
      }
    });

    this._setSelected(value);
    this._onChange(value);
  }

  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled; }
  registerOnChange(fn: any): void { this._onChange = fn; }
  registerOnTouched(fn: any): void { this._onTouched = fn; }
  registerOnValidatorChange(fn: () => void): void { this._onValidatorChange = fn; }

  validate(control: AbstractControl<any, any>): ValidationErrors { return null; }

  private _setSelected(template: Template) {
    this.value = template;
    this.select.writeValue(template);
    this.cdRef.markForCheck();
  }
}
