import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MailRecipient } from '../../mailing-types';

@Component({
  selector: 'cwt-contact-recipient',
  template: `
    <span>{{recipient?.label}}</span>
  `,
  styles: [
    `:host { display: flex; gap: 4px }`,
    `cwt-image { margin-inline-start: -4px; }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactRecipientComponent implements OnInit {

  @Input() recipient: MailRecipient<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
