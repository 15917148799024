<nz-select nzMode="tags" nzBorderless nzSelectOnTab
    class="rounded-tags"
    [(ngModel)]="value"
    [nzDisabled]="disabled"
    [nzCustomTemplate]="customTag"
    (ngModelChange)="onMailChanged($event)"
    [nzTokenSeparators]="[' ', ';', ',']"
    [nzPlaceHolder]="'field.mail-recipients' | translate">
    <nz-option *ngFor="let recipient of recipients" [nzValue]="recipient" nzLabel="{{recipient.label}} - {{recipient.email}}"></nz-option>
</nz-select>

<ng-template #customTag let-selected>
    <div [ngSwitch]="selected.nzValue?.origin">
        <cwt-contact-recipient *ngSwitchCase="'customer-contact'" [recipient]="selected.nzValue"></cwt-contact-recipient>
        <span *ngSwitchDefault>{{selected.nzLabel}}</span>
    </div>
</ng-template>