import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CwtFormModule } from '@cawita/core-front/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SharedCommonModule } from '../common';
import { FixSelectItemHeightDirective, ForwardCvaDirective } from './directives';
import { FormCardComponent } from './form-card';

@NgModule({
  imports: [
    CommonModule,
    CwtFormModule,
    NzCardModule,
    NzOutletModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ForwardCvaDirective,
    FixSelectItemHeightDirective,
  ],
  exports: [
    SharedCommonModule,
    ReactiveFormsModule,
    CwtFormModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NzDividerModule,
    NzGridModule,
    NzCardModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputNumberModule,
    FormCardComponent,
    ForwardCvaDirective,
    FixSelectItemHeightDirective
  ],
  declarations: [
    FormCardComponent
  ],
  providers: [],
})
export class SharedFormModule { }
