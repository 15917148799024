import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { CwtI18nService, CwtTranslatable, isset } from '@cawita/core-front';
import { NzModalService } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';

export type ConfirmationAlertConfig = {
    title: CwtTranslatable;
    message: CwtTranslatable | CwtTranslatable[];
    confirmText?: CwtTranslatable;
    cancelText?: CwtTranslatable;
    danger?: boolean;
}


@Injectable({ providedIn: 'root' })
export class AlertService {
    constructor(
        private alert: NzModalService,
        private i18n: CwtI18nService
    ) { }

    public confirm(options: ConfirmationAlertConfig): Promise<boolean> {
        return new Promise<boolean>(async (res) => {
            this.alert.create({
                nzTitle: await this.translateString(options.title),
                nzContent: await this.translateString(options.message),
                nzCancelText: await this.translateString(options.cancelText ?? 'action.cancel'),
                nzOkText: await this.translateString(options.confirmText ?? 'action.confirm'),
                nzOkDanger: options.danger,
                nzOnOk: () => res(true),
                nzOnCancel: () => res(false)
            });
        });
    }

    private async translateString(str: CwtTranslatable | CwtTranslatable[]): Promise<string> {
        const toTranslate = coerceArray(str)?.filter(isset);
        if (!isset(toTranslate) || !toTranslate?.length) return undefined;
        const strings = await Promise.all(toTranslate.map(t => lastValueFrom(this.i18n.get(t))));
        return strings.join('\n');
    }
}