import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[cwtGoBack]'
})
export class GoBackDirective {

  constructor(private location: Location) {
  }

  @HostListener('click', ['$event'])
  public click($event: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    this.location.back();
  }
}
