import permissions from './permissions.json';
import templateServiceShared from './template-service-shared';

export const AvailablePermissions = permissions;
export const TemplateService = templateServiceShared;

export type _AssignablePermissionsPerAccessType = Record<string, string[]>;
export type _AssignablePermissionsPerAccessTypeAndCategory = Record<string, Record<string, string[]>>;

export const AssignablePermissionsPerAccessType: _AssignablePermissionsPerAccessType = permissions
    .filter(perm => !perm.nonAssignable)
    .reduce((map, perm) => {
        perm.accessTypes.forEach(accessType => {
            if (!map[accessType]) map[accessType] = [perm.name];
            else map[accessType].push(perm.name);
        });
        return map;
    }, {});

export const AssignablePermissionsPerAccessTypeAndCategory: _AssignablePermissionsPerAccessType = permissions
    .filter(perm => !perm.nonAssignable)
    .reduce((map, perm) => {
        perm.accessTypes.forEach(accessType => {
            if (!map[accessType]) map[accessType] = {};
            if (!map[accessType][perm.category]) map[accessType][perm.category] = [perm.name];
            else map[accessType][perm.category].push(perm.name);
        });

        return map;
    }, {})