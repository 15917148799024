import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { normalizeString } from '@cawita/core-front';

@Component({
  selector: 'cwt-media-avatar-addon',
  template: `
    <div class="w-100 h-100 d-flex align-items-center justify-content-center"
      [style.background-color]="text | cwtInitials: length | textColor: 60">
      <span [style.color]="text | cwtInitials: length | textColor: 20">
          {{text | cwtInitials: length | uppercase}}
      </span>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
      user-select: none;
      font-size: calc(var(--media-width, var(--media-height)) * 0.4);
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaAvatarAddonComponent implements OnInit {

  @Input() text: string;
  @Input() length: number = 2;

  constructor() { }

  ngOnInit(): void { }

}
