import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwtCommonModule } from '@cawita/core-front';
import { CwtAssetModule } from '@cawita/core-front/asset';
import { CwtAuthModule } from '@cawita/core-front/auth';
import { CwtStateModule } from '@cawita/core-front/state';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import {
    CloseDrawerDirective,
    CloseModalDirective,
    ConfirmDirective,
    GoBackDirective,
    IfBreakpointDirective,
    LetResolvedDirective,
    PickFileDirective
} from './directives';
import {
    NamePipe,
    RatioPipe,
    TextColorPipe
} from './pipes';

@NgModule({
    imports: [],
    exports: [
        CommonModule,
        CwtStateModule,
        CwtCommonModule,
        CwtAuthModule,
        CwtAssetModule,
        RouterModule,
        NzTypographyModule,
        NzEmptyModule,
        NzSpaceModule,
        NzCardModule,
        NzGridModule,
        NzButtonModule,
        NzIconModule,
        NzModalModule,
        NzSpinModule,
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        NzToolTipModule,
        NzDividerModule,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective
    ],
    declarations: [
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective
    ],
    providers: [],
})
export class SharedCommonModule {
    static forRoot(): ModuleWithProviders<SharedCommonModule> {
        return {
            ngModule: SharedCommonModule,
            providers: []
        }
    }
}
