import { Injectable } from '@angular/core';
import { ConstraintOptions, MailConstraintHandler } from '../providers';
import { Observable } from 'rxjs';
import { MailParams } from '../mailing-types';

@Injectable({ providedIn: 'root' })
export class NoopConstraintHandler extends MailConstraintHandler {
    public check(params: MailParams, options: ConstraintOptions): boolean | Promise<boolean> | Observable<boolean> { return true; }
    public prompt(params: MailParams, options: ConstraintOptions): boolean | Observable<boolean> | Promise<boolean> { return true; }
    public resolve(params: MailParams, options: ConstraintOptions): boolean | Observable<boolean> | Promise<boolean> { return true; }
}