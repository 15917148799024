const { isEmptyOrNotSet, isset } = require('@hints/utils/data');
const { isType } = require('@hints/utils/types');

const templates = {
    forgottenPassword: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'string', label: 'template-variables.url', required: true },
        },
    },
    newPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'string', label: 'template-variables.url', required: true },
        },
    },
    askNewPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'string', label: 'template-variables.url', required: true },
        },
    },
    confirmationRegisterMail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'string', label: 'template-variables.url', required: true },
        },
    },
    generic: {
        type: 'mail',
        for: 'app',
        variables: {}
    }
};

/**
 * Get all variables matching use case
 * @param {string} useCase useCase
 * @returns {string[]} variables names
 */
function getUseCaseVariables(useCase) {
    if (!useCaseExists(useCase)) return [];
    const variables = templates[useCase].variables || {};
    return Object.keys(variables);
}

/**
 * Get all constraints matching use case
 * @param {string} useCase useCase
 * @returns {string[]} constraints
 */
function getUseCaseConstraints(useCase) {
    if (!useCaseExists(useCase)) return [];
    return templates[useCase].constraints || [];
}

/**
 * Get all common constraints for use cases
 * @param {string[]} useCases useCases
 * @returns {string[]} constraints that are in every useCases
 */
function getCommonUseCaseConstraints(useCases) {
    if (isEmptyOrNotSet(useCases)) return [];
    /** @type {string[][]} */
    const constraints = useCases.map(getUseCaseConstraints).filter(arr => !isEmptyOrNotSet(arr));
    if (constraints.length === 0) return [];
    if (constraints.length === 1) return constraints[0];
    const [firstConstraints, ...otherConstraints] = constraints;
    return firstConstraints.filter(constraint => otherConstraints.every(c => c.includes(constraint)));
}

/**
 * Get all required variables matching useCase
 * @param {string} useCase useCase 
 * @returns {string[]} required variables names
 */
function getRequiredUseCaseVariables(useCase) {
    const variables = getUseCaseVariables(useCase);
    return variables.filter(variable => !!templates[useCase].variables[variable].required);
}

/**
 * Get all use cases matching for field
 * @param {string} useCasesFor useCasesFor
 * @returns {string[]} useCases matching "for"
 */
function getUseCasesFor(useCasesFor) {
    if (isEmptyOrNotSet(useCasesFor)) return Object.keys(templates);
    return Object.keys(templates).filter(useCase => templates[useCase].for === useCasesFor);
}

/**
 * Parses content using simple processor
 * @param {string} useCase useCase
 * @param {string} content string template
 * @param {Record<string, any>} fields variables values 
 * @returns { { content: string, result: { failedVariables: string[], convertedVariables: string[] } } } results
 */
function parseContent(useCase, content, fields) {
    const result = { convertedVariables: [], failedVariables: [] };
    if (!useCaseExists(useCase)) return { content: '', result };

    const useCaseVariables = getUseCaseVariables(useCase);
    for (const variable in fields) {
        if (!useCaseVariables.includes(variable)) continue;
        const variableConfig = templates[useCase].variables[variable];
        if (isType(fields[variable], variableConfig.type)) {
            content = content.replace(new RegExp('{{' + variable + '}}', 'g'), isset(fields[variable]) ? fields[variable] : '');
            result.convertedVariables.push(variable);
        } else {
            result.failedVariables.push(variable);
        }
    }
    return { content, result };
}

function getTemplateVariables(content) {
    const regexp = /(?<=\{{).+?(?=\}})/g;
    return content.match(regexp) || [];
}

function getUseCaseType(useCase) { return templates[useCase].type; }
function getUseCaseFor(useCase) { return templates[useCase].for; }

/**
 * 
 * @param {string} useCase useCase
 * @param {string | undefined | null} type type of template to match
 * @returns {boolean}
 */
function useCaseExists(useCase, type = null) {
    if (!isset(templates[useCase])) return false;
    if (!isEmptyOrNotSet(type)) return templates[useCase].type === type;
    return true;
}

module.exports = {
    templates,
    templateUseCases: Object.keys(templates),
    templateTypes: Object.values(templates).reduce((types, template) => types.concat(template.type), []),
    templateFor: Object.values(templates).reduce((fors, template) => fors.concat(template.for), []),
    getUseCaseVariables,
    getUseCaseType,
    getUseCaseFor,
    getUseCaseConstraints,
    getRequiredUseCaseVariables,
    getUseCasesFor,
    parseContent,
    getTemplateVariables,
    getCommonUseCaseConstraints,
    useCaseExists
};