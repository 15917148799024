import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-media-loading-addon',
  template: `
    <nz-skeleton-element nzType="avatar" class="w-100 h-100" [nzActive]="true"></nz-skeleton-element>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
      user-select: none;
    }

    :host nz-skeleton-element ::ng-deep span {
      width: 100%;
      height: 100%;
      border-radius: 0px !important;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaLoadingAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
