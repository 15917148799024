import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'cwt-mailing-header',
  templateUrl: './mailing-header.component.html',
  styleUrls: ['./mailing-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingHeaderComponent implements OnInit {

  constructor(
    private ref: NzModalRef,
  ) { }

  ngOnInit(): void {
  }

  close() { this.ref.close(); }

}
