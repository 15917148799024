import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cwt-mailing-footer',
  templateUrl: './mailing-footer.component.html',
  styleUrls: ['./mailing-footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingFooterComponent implements OnInit {

  @Input() loading = false;
  @Input() formId: string;

  constructor() { }

  ngOnInit(): void {
  }

}
