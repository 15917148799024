import { Pipe, PipeTransform } from '@angular/core';
import { isset } from '@cawita/core-front';

@Pipe({ name: 'ratio', })
export class RatioPipe implements PipeTransform {
  transform(left: number, right: number) {
    if (!isset(right) || right === 0) return 0;
    return (left ?? 0) / right;
  }

}
